import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { AlternativesAlertText } from '@npaShared/consts/control-alternatives-text-alerts.const';
import { shouldUnprocessedInformationBeTakenIntoAccount } from './unprocessed-information-helper';

/**
 * Сообщение о наличии замечаний
 *
 * @param type вариант согласования
 * @param route маршрут
 * @param point активная точка
 * @param hasRemarksAlternatives признак наличия альтернативы в хотя бы одной ревизии
 * @returns
 */
export const remarksTextMessageByActionType = (
  type: ApproveTypeEnum,
  route: Route,
  point: RoutePoint,
  hasRemarksAlternatives: boolean,
): string => {
  const shouldUnprocessedInfoBeTakenIntoAccount = shouldUnprocessedInformationBeTakenIntoAccount(
    route,
    point,
    hasRemarksAlternatives,
  );

  if (shouldUnprocessedInfoBeTakenIntoAccount) {
    switch (type) {
      case ApproveTypeEnum.approve:
        return AlternativesAlertText.approveWithUnspentAlternatives;
      case ApproveTypeEnum.startApprovement:
        return AlternativesAlertText.startApprovementWithUnspentAlternatives;
      case ApproveTypeEnum.startOuterApprovement:
        return AlternativesAlertText.startOuterApprovementWithUnspentAlternatives;
    }
  }

  switch (type) {
    case ApproveTypeEnum.approve:
      return AlternativesAlertText.approve;
    case ApproveTypeEnum.approveLuz:
      return AlternativesAlertText.approveLuz;
    case ApproveTypeEnum.startApprovement:
      return AlternativesAlertText.startApprovement;
    case ApproveTypeEnum.startApprovementWithRemarks:
      return AlternativesAlertText.startApprovementWithRemarks;
    case ApproveTypeEnum.sendToOauLeader:
      return AlternativesAlertText.sendToOauLeader;
  }

  return '';
};
