import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { isReworkOnProjectCreatedPreviousSubPhase } from '@npaShared/helpers/route/subphase-helper';
import { Route } from '@npaShared/models/route/route.models';
import { DocumentRevisionInfoModel } from '@npaShared/models/document/documents-revision-info.model';
import { DocumentGroupsEnum } from '@npaShared/enums/document-group.enum';
import { DocumentTypesEnum } from '@npaShared/enums/document-types.enum';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';

/**
 * Нужно ли использовать версии для подписания
 *
 * @param route
 * @param selectedPointId
 * @returns
 */
export const needToCreateVersionsForSigning = (route: Route, selectedPointId: number): boolean => {
  const selectedPoint = getPointByIdWithAdditionalInfo(route, selectedPointId);

  if (!EqualActiveResolutions.includes(selectedPoint.taskResolution)) {
    return false;
  }

  if (selectedPoint.pointRoleId !== EmployeeRouteTypes.leader) {
    return false;
  }

  const isTherePointOnApprovementAfterRework = isReworkOnProjectCreatedPreviousSubPhase(route, selectedPoint.id);
  if (!isTherePointOnApprovementAfterRework) {
    return false;
  }

  return selectedPoint.iteration > 1;
};

export const getVersionsWithRemarks = (documents: DocumentInfoResponseModel[]): DocumentRevisionInfoModel[] => {
  const versionsWithRemarks: DocumentRevisionInfoModel[] = [];

  documents.forEach((document) => {
    const version = document.versions[0];
    const isMainOrAccompanying = [DocumentGroupsEnum.main, DocumentGroupsEnum.accompanying].includes(
      document.documentGroup,
    );
    const notSpreadsheet = document.documentType.id !== DocumentTypesEnum.SPREADSHEET;

    if (isMainOrAccompanying && notSpreadsheet && version?.revision) {
      versionsWithRemarks.push({
        documentId: document.id,
        ldeId: version.ldeDocumentId,
        revision: version.revision,
        main: document.documentGroup === DocumentGroupsEnum.main,
      });
    }
  });

  return versionsWithRemarks;
};
