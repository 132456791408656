/**
 * Типы этапов на маршруте
 */
export enum RoutePhaseTypes {
  /**
   * Проект создан
   */
  projectCreated = 1,
  /**
   * Ответственный исполнитель
   */
  responsibleExecutor = 2,
  /**
   * Вопрос внесен
   */
  questionProvided = 3,
  /**
   * ПУ ПМ
   */
  puPm = 4,
  /**
   * Проект представлен
   */
  projectProvided = 5,
  /**
   * ОАУ ПМ
   */
  oauPm = 6,
  /**
   * Проект создан. Инициация
   */
  projectCreatedInitiation = 7,
  /**
   * Проект создан. Разработка
   */
  projectCreatedDevelopment = 8,
  /**
   * Проект создан. Согласование
   */
  projectCreatedApprovement = 9,
  /**
   * Проект создан. Доработка
   */
  projectCreatedRework = 10,
  /**
   * Ответственный исполнитель. Инициация
   */
  responsibleExecutorInitiation = 11,
  /**
   * Ответственный исполнитель. Разработка и согласование проекта ЛУЗ
   */
  responsibleExecutorApprovement = 12,
  /**
   * Вопрос внесен. Инициация
   */
  questionProvidedInitiation = 13,
  /**
   * Вопрос внесен. Разработка и согласование проекта ЛУЗ
   */
  questionProvidedApprovement = 14,
  /**
   * ПУ ПМ. Инициация
   */
  puPmInitiation = 15,
  /**
   * ПУ ПМ. Разработка и согласование
   */
  puPmApprovement = 16,
  /**
   * Проект представлен. Согласование
   */
  projectProvidedApprovement = 17,
  /**
   * ОАУ ПМ. Инициация
   */
  oauPmInitiation = 18,
  /**
   * ОАУ ПМ. Предварительная экспертиза
   */
  oauPmExpertise = 19,
  /**
   * ОАУ ПМ. Разработка и согласование
   */
  oauPmApprovement = 20,
  /**
   * Проект согласован
   */
  projectApproved = 21,
  /**
   * Проект согласован. Инициация
   */
  projectApprovedInitiation = 22,
  /**
   * Проект согласован. Согласование
   */
  projectApprovedApprovement = 23,
  /**
   * Оформление в ОАУ
   */
  oauRegistration = 24,
  /**
   * Оформление в ОАУ. Инициация
   */
  oauRegistrationInitiation = 25,
  /**
   * Оформление в ОАУ. Согласование
   */
  oauRegistrationApprovement = 26,
  /**
   * Предварительное согласование
   */
  preliminaryApprovement = 27,
  /**
   * Предварительное согласование. Инициация
   */
  preliminaryApprovementInitiation = 28,
  /**
   * Предварительное согласование. Согласование
   */
  preliminaryApprovementApprovement = 29,
  /**
   * Предварительное согласование. Доработка
   */
  preliminaryApprovementRevision = 36,
  /**
   * Согласительное совещание
   */
  conciliation = 30,
  /**
   * Согласительное совещание. Поручение
   */
  conciliationInitiation = 31,
  /**
   * Согласительное совещание. Согласование
   */
  conciliationApprovement = 32,
  /**
   * Пользовательский этап
   */
  userPhase = 33,
  /**
   * Пользовательский этап. Поручение
   */
  userPhaseInitiation = 34,
  /**
   * Пользовательский этап. Согласование
   */
  userPhaseApprovement = 35,
}

export const getProjectCreatedSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.projectCreatedInitiation,
  RoutePhaseTypes.projectCreatedDevelopment,
  RoutePhaseTypes.projectCreatedApprovement,
  RoutePhaseTypes.projectCreatedRework,
];

export const getResponsibleExecutorSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.responsibleExecutorInitiation,
  RoutePhaseTypes.responsibleExecutorApprovement,
];

export const getQuestionProvidedSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.questionProvidedInitiation,
  RoutePhaseTypes.questionProvidedApprovement,
];

export const getPuPmSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.puPmInitiation,
  RoutePhaseTypes.puPmApprovement,
];

export const getProjectProvidedSubphases = (): RoutePhaseTypes[] => [RoutePhaseTypes.projectProvidedApprovement];

export const getProjectApprovedSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.projectApprovedInitiation,
  RoutePhaseTypes.projectApprovedApprovement,
];

export const getOauPmSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.oauPmInitiation,
  RoutePhaseTypes.oauPmApprovement,
  RoutePhaseTypes.oauPmExpertise,
];

export const getOauRegistrationSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.oauRegistrationInitiation,
  RoutePhaseTypes.oauRegistrationApprovement,
];

export const getPreliminaryApprovementSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.preliminaryApprovementInitiation,
  RoutePhaseTypes.preliminaryApprovementApprovement,
];

export const getConciliationSubphases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.conciliationInitiation,
  RoutePhaseTypes.conciliationApprovement,
];

export const getAllApprovementSubhases = (): RoutePhaseTypes[] => [
  RoutePhaseTypes.projectCreatedApprovement,
  RoutePhaseTypes.responsibleExecutorApprovement,
  RoutePhaseTypes.questionProvidedApprovement,
  RoutePhaseTypes.puPmApprovement,
  RoutePhaseTypes.projectProvidedApprovement,
  RoutePhaseTypes.projectApprovedApprovement,
  RoutePhaseTypes.oauPmApprovement,
  RoutePhaseTypes.oauRegistrationApprovement,
  RoutePhaseTypes.conciliationApprovement,
  RoutePhaseTypes.preliminaryApprovementApprovement,
  RoutePhaseTypes.userPhaseApprovement,
];
