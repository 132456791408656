import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { Route, RoutePoint, RoutePointWithAdditionalInfo, RouteSubPhase } from '@npaShared/models/route/route.models';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { getMainVerticalPointsFlatOnSubPhaseByPointId, getNextPointOnSubPhase } from './subphase-helper';
import { getPointsCurrentUser } from './get-points-current-user';

/** Получение всех точек-потомков для текущей точки
 *
 * @returns вернет все боковые точки для которых переданная точка является предком
 */
export const getAllSidePointsOfPoint = (point: RoutePoint, children: RoutePoint[] = []): RoutePoint[] => {
  if (point.childrenPoint?.length) {
    children.push(...point.childrenPoint);
    point.childrenPoint.map((childPoint) => getAllSidePointsOfPoint(childPoint, children));
  }
  return children;
};

/**
 * вернёт одну точку этапа:
 * руководителя, если его нет, то первую точку подэтапа
 *
 * @param subphase
 */
export const getOnePointOnSubphase = (subphase: RouteSubPhase): RoutePoint => {
  const lastSubphaseWithOnlyLeader: RoutePoint[] = subphase.points.find((ap) =>
    ap.find((p) => p.pointRoleId === EmployeeRouteTypes.leader),
  );

  return lastSubphaseWithOnlyLeader?.length ? lastSubphaseWithOnlyLeader[0] : subphase.points[0][0];
};

/**
 * @returns  последнюю точку из массива по переданной роли
 * */
export const getLastPointByRole = (points: RoutePoint[], pointRoleId: EmployeeRouteTypes): RoutePoint | null => {
  const list = points.filter((point) => point.pointRoleId === pointRoleId);

  return !list.length ? null : list[list.length - 1];
};

/**
 * @returns true если точка исполнителя является последней среди исполнителей
 * */
export const isLastExecutorPoint = (points: RoutePoint[], pointId: number): boolean => {
  const lastPoint = getLastPointByRole(points, EmployeeRouteTypes.executor);

  return !lastPoint ? false : lastPoint.id === pointId;
};

/**
 * @returns true если точка соисполнителя находится в последней точке исполнителя
 * */
export const isCoExecutorPointInLastExecutorPoint = (points: RoutePoint[], pointId: number): boolean => {
  const lastPoint = getLastPointByRole(points, EmployeeRouteTypes.executor);

  if (!lastPoint) {
    return false;
  }

  return !!lastPoint.childrenPoint?.find((point) => point.id === pointId);
};

/** @returns true, если переданная точка параллельная */
export const pointIsParallel = (route: Route, pointId: number): boolean => {
  let isParallel = false;

  route.route.phases.forEach((arrayPhase) => {
    arrayPhase.forEach((p) =>
      p.iterations.forEach((i) =>
        i.subPhases.forEach((sp) =>
          sp.points.forEach((ap) => {
            const requiredPoint = ap.find((point) => point.id === pointId);
            if (requiredPoint) {
              isParallel = ap.length > 1;
            }
          }),
        ),
      ),
    );
  });

  return isParallel;
};

/**
 * @param route
 * @param childPointId id дочерней точки
 * @returns true, если родительская точка параллельная
 */
export const isParallelParentPoint = (route: Route, childPointId: number): boolean => {
  let isParallel = false;

  route.route.phases.forEach((arrayPhase) => {
    arrayPhase.forEach((phase) =>
      phase.iterations.forEach((iteration) =>
        iteration.subPhases.forEach((subPhase) =>
          subPhase.points.forEach((arrayPoint) => {
            const someCurrentPointInChildren = arrayPoint.find((point) =>
              point.childrenPoint.some((child) => child.id === childPointId),
            );

            if (someCurrentPointInChildren) {
              isParallel = arrayPoint.length > 1;
            }
          }),
        ),
      ),
    );
  });

  return isParallel;
};

/**
 * получить точку с дополнительной информацией по её id
 *
 * @param route
 * @param pointId
 */
export const getPointByIdWithAdditionalInfo = (route: Route, pointId: number): RoutePointWithAdditionalInfo | null => {
  let selectedPoint = null;
  let selectedPointWithAdditionalInfo = null;

  route?.route?.phases?.forEach((arrayPhases) =>
    arrayPhases?.forEach((phase) => {
      phase.iterations.forEach((i) =>
        [...i.subPhases].reverse().forEach((sp) =>
          sp.points.forEach((ap) => {
            if (selectedPoint) {
              return;
            }

            let sidePoints: RoutePoint[] = [];
            ap.forEach((rootPoint) => (sidePoints = [...sidePoints, ...getAllSidePointsOfPoint(rootPoint)]));

            selectedPoint = [...ap, ...sidePoints].find((point) => point?.id === pointId);
            if (!selectedPoint) {
              return;
            }
            selectedPointWithAdditionalInfo = {
              ...selectedPoint,
              phaseId: phase.id,
              subPhaseId: sp.id,
              phaseIterationNumber: i.number,
            } as RoutePointWithAdditionalInfo;
          }),
        ),
      );
    }),
  );

  return selectedPointWithAdditionalInfo;
};

/** Проверяет присутствие искомой точки среди дочерних точек */
export const isPointInChild = (parentPoint: RoutePoint, findingPointId: number): boolean => {
  if (!findingPointId) {
    return false;
  }

  return !!parentPoint?.childrenPoint.filter((point) => {
    if (point.childrenPoint?.length) {
      return point.id === findingPointId || isPointInChild(point, findingPointId);
    }
    return point.id === findingPointId;
  }).length;
};

/** Является ли переданная точка последней перед точкой руководителя или она сама последняя точка - руководитель */
export const nextPointOrCurrentPointIsLeader = (route: Route, point: RoutePoint): boolean => {
  if (!point) {
    return false;
  }

  const nextPoint = getNextPointOnSubPhase(route, point.id);
  /** если активная точка исполнителя, то у руководителя ещё нет роли `EmployeeRouteTypes.leader`,
   * поэтому проверяю есть ли за `nextPoint` ещё точка - если нет, то `nextPoint` это руководитель */
  const nextPointForNextPoint = nextPoint.length ? getNextPointOnSubPhase(route, nextPoint[0].id) : [];
  const isLeaderNextPoint =
    nextPoint.some((p) => p.pointRoleId === EmployeeRouteTypes.leader) || !nextPointForNextPoint.length;
  const isLastCurrentPoint = !Boolean(nextPoint.length);

  return isLeaderNextPoint || isLastCurrentPoint;
};

/** Является ли переданный пользователь автором на маршруте */
export const isUserAuthor = (route: Route, currentUser: UserInfoResponseModel): boolean => {
  const currentUserPoints = getPointsCurrentUser(route, currentUser);

  return currentUserPoints.some((p) => p.pointRoleId === EmployeeRouteTypes.author);
};

/**
 * Получить для переданной точки родительскую на главной вертикали
 *
 * @param route маршрут
 * @param currentPointId id точки, для которой надо найти родительскую
 * @returns родительская точка на главной вертикале
 */
export const getParentPointOnMainVertical = (route: Route, currentPointId: number): RoutePoint | null => {
  let parentPointOnMainVertical = null;
  const subPhasePoints = getMainVerticalPointsFlatOnSubPhaseByPointId(route, currentPointId);

  subPhasePoints.forEach((point) => {
    if (parentPointOnMainVertical) {
      return;
    }

    const hasCurrentPoint = isPointInChild(point, currentPointId);
    if (hasCurrentPoint) {
      parentPointOnMainVertical = point;
    }
  });

  return parentPointOnMainVertical;
};
