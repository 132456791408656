import { ProjectGroupTypeEnum } from '@enums/project-group-type.enum';
import { DocumentPackageAdditionalInfoResponseModel } from '@npaShared/models/document-package/document-package-additional-info.response.model';
import { DocumentPackageProjectTypeModel } from '@npaShared/models/document-package/document-package-project-type.model';
import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';
import { DocumentPackageResponseModel } from '@npaShared/models/document-package/document-package.response.model';

/** является ли переданный ПД нормативно правовым актом - НПА */
export const isLegalActDocumentPackage = (documentPackage: DocumentPackageModels): boolean =>
  documentPackage?.projectType?.projectGroupType?.id === ProjectGroupTypeEnum.LEGAL_ACTS;

/** является ли переданный ПД локально правовым актом - ЛПА */
export const isLocalLegalActDocumentPackage = (documentPackage: DocumentPackageModels): boolean =>
  documentPackage?.projectType?.projectGroupType?.id === ProjectGroupTypeEnum.LOCAL_LEGAL_ACTS;

type DocumentPackageModels =
  | DocumentPackageResponseModel
  | DocumentPackageAdditionalInfoResponseModel
  | (DocumentPackageShortResponseModel & { projectType: DocumentPackageProjectTypeModel });
