import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '@npaShared/models/document/document-info.response.model';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { getSigningRevision } from '@npaShared/helpers/document-revision';
import {
  ChangeRevisionFromSocket,
  ResetDocumentsState,
  SetActiveDocument,
  SetDocuments,
  SetSigningVersions,
  UpdateActiveVersion,
} from './documents.action';

interface DocumentsStoreModel {
  documents: DocumentInfoResponseModel[];
  /** Версии документов для подписи */
  signingVersions: SigningVersionModel[];
  /** Активный документ */
  activeDocument: DocumentInfoResponseModel | null;
  actualVersion: DocumentVersionInfoModel | null;
  /** Признак наличия альтернативы в хотя бы одной ревизии */
  isHasRemarksAlternatives: boolean;
}

const initialState: DocumentsStoreModel = {
  documents: [],
  signingVersions: [],
  activeDocument: null,
  actualVersion: null,
  isHasRemarksAlternatives: false,
};

@Injectable()
@State<DocumentsStoreModel>({
  name: 'documents',
  defaults: initialState,
})
export class DocumentsState {
  @Selector()
  public static documents(state: DocumentsStoreModel): DocumentInfoResponseModel[] {
    return state.documents;
  }

  @Selector()
  public static activeDocument(state: DocumentsStoreModel): DocumentInfoResponseModel {
    return state.activeDocument;
  }

  @Selector()
  public static actualVersion(state: DocumentsStoreModel): DocumentVersionInfoModel {
    return state.actualVersion;
  }

  @Selector()
  public static signingVersions(state: DocumentsStoreModel): SigningVersionModel[] {
    return state.signingVersions;
  }

  @Action(SetDocuments)
  public setDocuments({ patchState }: StateContext<DocumentsStoreModel>, { documents }: SetDocuments): void {
    patchState({ documents });
  }

  @Action(SetActiveDocument)
  public setActiveDocument({ patchState }: StateContext<DocumentsStoreModel>, { document }: SetActiveDocument): void {
    patchState({ activeDocument: document, actualVersion: document?.versions[0] });
  }

  @Action(UpdateActiveVersion)
  public updateActiveVersion({ patchState, getState }: StateContext<DocumentsStoreModel>): void {
    patchState({ actualVersion: getState().activeDocument?.versions[0] });
  }

  @Action(SetSigningVersions)
  public setSigningVersion(
    { patchState }: StateContext<DocumentsStoreModel>,
    { signingVersionDocuments }: SetSigningVersions,
  ): void {
    patchState({ signingVersions: signingVersionDocuments });
  }

  @Action(ChangeRevisionFromSocket)
  public changeRevisionFromSocket(
    { patchState, getState }: StateContext<DocumentsStoreModel>,
    { signingVersion }: ChangeRevisionFromSocket,
  ): void {
    const versions = getState().signingVersions;
    const signingRevision = getSigningRevision(signingVersion, false);

    const updatingVersions: SigningVersionModel[] = versions.map((version) => {
      if (version.ldeDocumentId === signingVersion.ldeDocumentId) {
        const updatingVersion: SigningVersionModel = { ...version, ldeRevisionId: signingRevision };
        return updatingVersion;
      }

      return version;
    });

    patchState({ signingVersions: updatingVersions });
  }

  @Action(ResetDocumentsState)
  public resetDocumentsState({ setState }: StateContext<DocumentsStoreModel>): void {
    setState(initialState);
  }
}
