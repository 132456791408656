import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { isReworkOnProjectCreatedPreviousSubPhase } from '@npaShared/helpers/route/subphase-helper';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { nextPointOrCurrentPointIsLeader } from '@npaShared/helpers/route/point-helper';
import { RoutePhaseTypes } from '../../document-agreement/enums/route-phase-types.enum';

/**
 * Нужно ли учитывать информацию о неотработанных замечаниях/альтернативах/комментариях
 *
 * @param route маршрут
 * @param point активная точка
 * @param remarks список с замечаниями/альтернативами
 * @returns
 */
export const shouldUnprocessedInformationBeTakenIntoAccount = (
  route: Route,
  point: RoutePoint,
  hasRemarksAlternatives: boolean,
): boolean => {
  if (!hasRemarksAlternatives) {
    return false;
  }

  return needToCalculateUnprocessedInformationByPoint(route, point);
};

/**
 * Нужно ли рассчитывать информацию о неотработанных замечаниях/альтернативах/комментариях
 *
 * @param route маршрут
 * @param point активная точка
 * @returns
 */
const needToCalculateUnprocessedInformationByPoint = (route: Route, point: RoutePoint): boolean => {
  const isActivePoint = EqualActiveResolutions.includes(point.taskResolution);
  if (!isActivePoint) {
    return false;
  }

  const neededRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.author];
  if (!neededRoles.includes(point.pointRoleId)) {
    return false;
  }

  const isNextPointLeader = nextPointOrCurrentPointIsLeader(route, point);

  const isTherePointOnRework = point.phaseTypeId === RoutePhaseTypes.projectCreatedRework;
  if (isTherePointOnRework && point.pointRoleId === EmployeeRouteTypes.author && isNextPointLeader) {
    return true;
  }

  const projectCreatedReworkIsPreviousSubPhase = isReworkOnProjectCreatedPreviousSubPhase(route, point.id);
  const isTherePointOnReworkOrPrevSubPhaseRework = isTherePointOnRework || projectCreatedReworkIsPreviousSubPhase;
  return isTherePointOnReworkOrPrevSubPhaseRework && isNextPointLeader;
};

/**
 * Нужно ли запрашивать информацию о неотработанных замечаниях/альтернативах/комментариях с бека
 *
 * @param route маршрут
 * @param point активная точка
 * @returns
 */
export const needToRequestUnprocessedInformationByPoint = (route: Route, point: RoutePoint): boolean => {
  const isActivePoint = EqualActiveResolutions.includes(point.taskResolution);
  if (!isActivePoint) {
    return false;
  }

  const neededRoles = [EmployeeRouteTypes.author, EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader];
  if (!neededRoles.includes(point.pointRoleId)) {
    return false;
  }

  const isTherePointOnRework = point.phaseTypeId === RoutePhaseTypes.projectCreatedRework;
  if (isTherePointOnRework) {
    return true;
  }

  const projectCreatedReworkIsPreviousSubPhase = isReworkOnProjectCreatedPreviousSubPhase(route, point.id);
  return projectCreatedReworkIsPreviousSubPhase;
};
