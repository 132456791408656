enum DocumentRemarksText {
  documentsWithRemarks = 'В документах раздела «Проект, приложения и пояснительная записка» есть замечания/альтернативы/комментарии',
  documentsWithUnprocessedRemarks = 'В документах раздела «Проект, приложения и пояснительная записка» есть неотработанные замечания/альтернативы/комментарии',
}

export const AlternativesAlertText = {
  documentsHaveAlternatives: 'Документы с замечаниями/альтернативами/комментариями:',
  documentsHaveUnspentAlternatives: 'Документы с неотработанными замечаниями/альтернативами/комментариями:',

  approve: `Согласование без замечаний недоступно. ${DocumentRemarksText.documentsWithRemarks}`,
  approveWithUnspentAlternatives: `Согласование без замечаний недоступно. ${DocumentRemarksText.documentsWithUnprocessedRemarks}`,
  approveLuz: `Согласование с замечаниями недоступно. ${DocumentRemarksText.documentsWithRemarks}`,
  startApprovement: `Отправка на согласование без замечаний недоступна. ${DocumentRemarksText.documentsWithRemarks}`,
  startApprovementWithUnspentAlternatives: `Отправка на согласование без замечаний недоступна. ${DocumentRemarksText.documentsWithUnprocessedRemarks}`,
  startApprovementWithRemarks: `Отправка на согласование с замечаниями недоступна. ${DocumentRemarksText.documentsWithRemarks}`,
  startOuterApprovementWithUnspentAlternatives: `Отправка на согласование недоступна. ${DocumentRemarksText.documentsWithUnprocessedRemarks}`,
  sendToOauLeader: `Направление руководителю недоступно. ${DocumentRemarksText.documentsWithRemarks}`,
} as const;
