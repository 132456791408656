import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { HttpStatusCode } from '@angular/common/http';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { filteringBlankDocuments } from '@npaShared/functions/filtering-documents';

/**
 * Получение модели ревизий документа
 *
 * @param document документ
 * @returns модель ревизий документа
 */
export const getDocumentRevision = (
  document: DocumentInfoResponseModel,
  signableVersions: SigningVersionModel[] = [],
): DocumentRevisionModel | null => {
  if (!document) {
    return null;
  }

  if (signableVersions?.length) {
  }

  return {
    documentId: document.id,
    ldeId: document.ldeDocumentId,
    revision: document.versions[0].revision,
  };
};

export const getDocumentRevisionsForSignature = (
  documents: DocumentInfoResponseModel[],
  signingVersions: SigningVersionModel[],
): DocumentRevisionModel[] => {
  const documentsForDecisionAction = [...filteringBlankDocuments(documents)];
  let revisionForSign = documentsForDecisionAction.map((d) => getDocumentRevision(d));

  if (signingVersions.length) {
    revisionForSign = revisionForSign.map((r) => {
      const signingVersion = signingVersions.find((v) => r.documentId === v.documentId);
      if (signingVersion) {
        return { ...r, revision: getSigningRevision(signingVersion) };
      }
      return r;
    });
  }

  return revisionForSign;
};

/**
 * Получить ревизию переданного документа для подписания
 *
 * @param signingVersion версия документа
 * @param isNotFromSocket версия получена не из websocket
 * @returns
 */
export const getSigningRevision = (signingVersion: SigningVersionModel, isNotFromSocket = true): string => {
  if (!signingVersion) {
    return '';
  }

  let revisions: { [key: number]: string } = {
    [HttpStatusCode.Ok]: signingVersion.ldeRevisionId,
  };

  if (isNotFromSocket) {
    revisions = { ...revisions, [HttpStatusCode.NoContent]: signingVersion.parentLdeRevisionId };
  }

  return revisions[signingVersion.ldeResponseCode] || '';
};
