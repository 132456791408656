import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';
import { PointLogicFactory } from '@npaCore/point-logic/point-logic.factory';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { ButtonsTextEnum } from '@npaShared/enums/buttons-text.enum';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { Route, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { AgreementNavigateService } from '@npaShared/services/agreement-navigate.service';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { Select, Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';
import { AgreementSignService, ParamsForPreparationSigning } from '@npaCore/services/signing/agreement-sign.service';
import { getDocumentRevision } from '@npaShared/helpers/document-revision';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { RefreshRouteHelperService } from '@npaShared/services/refresh-route-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { modalIdConfirmAction } from '@npaShared/consts/modals-id.const';
import { CertificatesSettingsNpaModel } from '@models/settings/certificates-settings.model';
import { filteringBlankDocuments } from '@npaShared/functions/filtering-documents';
import { VisibilityContextMenuInButtonsDecisionState } from '@npaShared/store/visibility-context-menu-in-buttons-decision/visibility-context-menu-in-buttons-decision.state';
import {
  CloseContextMenuInButtonsDecision,
  ToggleContextMenuInButtonsDecision,
} from '@npaShared/store/visibility-context-menu-in-buttons-decision/visibility-context-menu-in-buttons-decision.action';
import { ActiveCategoryState } from '@npaShared/store/active-category/active-category.state';
import { DecisionActionsAvailabilityState } from '@npaShared/store/decision-actions-availability/decision-actions-availability.state';
import { needDisabledButtonToDependingRemarks } from '@npaShared/helpers/route/buttons-disabled.helper';
import { getAllActualVersions } from '@npaShared/helpers/document-version.helper';
import { ModalService } from '@shared/services/modal.service';
import { DOCUMENTS_TEXT_ERRORS, GENERAL_TEXT_ERRORS } from '@const/global-text-errors.const';
import { hasAnyDocumentInMainDocumentGroup } from '@npaShared/helpers/document.helper';
import { remarksTextMessageByActionType } from '@npaShared/functions/remarks-text-message-by-action-type';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { SelectedRoutePointService } from '../../../document-package/services/selected-route-point.service';
import { StartDevelopmentComponent } from '../../../document-agreement/components/start-development/start-development.component';

@UntilDestroy()
@Component({
  selector: 'app-buttons-decision',
  templateUrl: './buttons-decision.component.html',
  styleUrls: ['./buttons-decision.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsDecisionComponent implements OnInit, OnDestroy {
  @Input() public route: Route;
  @Input() public documentPackage: DocumentPackageStoreModel;
  @Input() public hasApprovmentValidation$: Observable<boolean>;
  @Input() public showArrows = true;
  /** На данный момент в системе пользователь, который зашел "под кем-то"  */
  @Input() public isDelegating: boolean;
  @Input() public isEnabledLeftArrow = true;
  @Input() public isEnabledRightArrow = true;

  @Output() public arrowsActions: EventEmitter<ActionsFooterWithArrow> = new EventEmitter();
  @Output() public successStartedDevelopment: EventEmitter<void> = new EventEmitter();

  /** открыто ли в данный момент контекстное меню с кнопками вынесения решения */
  @Select(VisibilityContextMenuInButtonsDecisionState.isVisibleContextMenu)
  public visibilityContextMenu$!: Observable<boolean>;

  @Select(DecisionActionsAvailabilityState.decisionActionsIsAvailable)
  public decisionActionsIsAvailable$: Observable<boolean>;

  public readonly buttonTheme = ButtonThemeEnum;
  public readonly buttonsText = ButtonsTextEnum;
  public readonly employeeRouteTypes = EmployeeRouteTypes;

  /** надо ли показывать кнопку "Направить руководителю" */
  public isShowSendToLeader = false;
  /** надо ли показывать кнопку "Направить замечания без согласования" */
  public isShowSendRemarksToAuthor = false;
  /** надо ли показывать кнопку "Добавить исполнителя" */
  public isShowAddExecutor = false;
  /** надо ли показывать кнопку "Перенаправить" */
  public isShowRedirect = false;
  /** надо ли показывать кнопки "Отправить на согласование" и "Отправить на согласование с замечаниями" */
  public isShowStartApprovement = false;
  /** надо ли показывать кнопку "Добавить согласующего" */
  public isShowAddCoordinator = false;
  /** надо ли показывать кнопку "взять в работу" */
  public isShowStartDevelopment = false;
  /** видимость кнопки "Согласовать" */
  public isShowApprove = false;
  /** видимость кнопки "Согласовать с замечаниями" */
  public isShowApproveWithRemarks = false;
  /** видимость кнопки "Вернуть" */
  public isShowReturn = false;
  /** видимость кнопки "Передать на ознакомление" */
  public isShowAddIntroducer = false;
  /** видимость кнопки "Ознакомлен" */
  public isShowApproveIntroduction = false;
  /** видимость кнопки "Начать доработку" */
  public isShowStartRework = false;
  /** видимость кнопки Отправить на внешнее согласование */
  public isStartOuterApprovement = false;

  /** сообщение при блокировании кнопок */
  public warningMessageAboutDisablingButton = '';

  /** надо ли дисейблить кнопку "Согласовать" при включенной настройки подписания без ввода коммента */
  public isDisabledApprove = false;
  /** надо ли дисейблить кнопку "Согласовать с замечаниями" при включенной настройки подписания без ввода коммента */
  public isDisabledApproveLuz = false;

  public submitting$ = this.agreementSignService.isSubmitting;

  private activePoint: RoutePoint;
  private activePointWithAdditionalInfo: RoutePointWithAdditionalInfo;
  private settings: CertificatesSettingsNpaModel;

  constructor(
    private store: Store,
    private packagesStore: DocumentPackagesStoreService,
    private agreementNavigate: AgreementNavigateService,
    private modalController: ModalController,
    private modalService: ModalService,
    private activeRoutePointService: SelectedRoutePointService,
    private documentApi: DocumentPackageApiService,
    private agreementSignService: AgreementSignService,
    private refreshRouteHelper: RefreshRouteHelperService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.settings = this.store.selectSnapshot(SettingsState.certificateSettingsNpa);

    const selectedPoint$ = this.activeRoutePointService.getSelectedPoint$();
    const alternativesRemarks$ = this.packagesStore.getDocumentsAlternatives();

    combineLatest([selectedPoint$, alternativesRemarks$])
      .pipe(untilDestroyed(this))
      .subscribe(([selectedPoint, alternativesRemarks]) => {
        this.activePoint = selectedPoint;
        this.activePointWithAdditionalInfo = getPointByIdWithAdditionalInfo(this.route, selectedPoint?.id);
        this.initializeLogic(this.activePoint);

        this.isDisabledApprove =
          this.settings.singingWithoutConfirmationNpa &&
          needDisabledButtonToDependingRemarks(
            ApproveTypeEnum.approve,
            this.route,
            this.activePoint,
            this.packagesStore.hasRemarksAlternatives,
            alternativesRemarks,
          );
        this.isDisabledApproveLuz =
          this.settings.singingWithoutConfirmationNpa &&
          needDisabledButtonToDependingRemarks(
            ApproveTypeEnum.approveLuz,
            this.route,
            this.activePoint,
            this.packagesStore.hasRemarksAlternatives,
            alternativesRemarks,
          );

        this.fillWarningMessage();
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.modalController.dismiss(undefined, undefined, modalIdConfirmAction);
    this.store.dispatch(CloseContextMenuInButtonsDecision);
  }

  /** нажатие на Вынести решение/Назад */
  public toggleContextMenuVisibility(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    const oldValue = this.store.selectSnapshot(VisibilityContextMenuInButtonsDecisionState.isVisibleContextMenu);
    this.store.dispatch(new ToggleContextMenuInButtonsDecision(oldValue));
  }

  /** Нажатие кнопки "Согласовать" */
  public onApprove(): void {
    const validSignature = this.checkCertificate();
    if (!validSignature) {
      return;
    }

    if (this.settings.singingWithoutConfirmationNpa) {
      this.approveWithoutRedirect(ApproveTypeEnum.approve);
      return;
    }

    this.hasApprovmentValidation$.pipe(first()).subscribe((hasApprovment) => {
      const hasApprovmentValidation = hasApprovment;
      this.agreementNavigate.onApprove(
        this.documentPackage?.id,
        hasApprovmentValidation,
        this.activePoint,
        this.route.route.id,
      );
    });
  }

  /** Нажатие кнопки "Согласовать с замечаниями" */
  public onApproveLuz(): void {
    const validSignature = this.checkCertificate();
    if (!validSignature) {
      return;
    }

    if (this.settings.singingWithoutConfirmationNpa) {
      this.approveWithoutRedirect(ApproveTypeEnum.approveLuz);
      return;
    }

    this.hasApprovmentValidation$.pipe(first()).subscribe((hasApprovment) => {
      const hasApprovmentValidation = hasApprovment;
      this.agreementNavigate.onApproveLuz(
        this.documentPackage?.id,
        hasApprovmentValidation,
        this.activePoint,
        this.route.route.id,
      );
    });
  }

  /** Нажатие кнопки "Вернуть" */
  public onReturn(): void {
    this.agreementNavigate.onReturn(this.route.route.id, this.documentPackage.id, this.activePoint.id);
  }

  /** Нажатие кнопки "Перенаправить" */
  public onRedirect(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    this.agreementNavigate.onRedirect(this.route.route.id, this.documentPackage.id, this.activePoint.id);
  }

  /** Нажатие кнопки "Направить замечания автору без согласования" */
  public async onSendRemarksToAuthor(): Promise<void> {
    const validSignature = this.checkCertificate();
    if (!validSignature) {
      return;
    }

    if (this.settings.singingWithoutConfirmationNpa) {
      this.approveWithoutRedirect(ApproveTypeEnum.sendRemarksToAuthor);
      return;
    }

    this.agreementNavigate.onSendRemarksToAuthor(this.documentPackage.id, this.activePoint, this.route.route.id);
  }

  /** Нажатие кнопки "Направить руководителю" */
  public onSendToOauLeader(): void {
    this.agreementNavigate.onSendToOauLeader(this.route.route.id, this.documentPackage.id, this.activePoint.id);
  }

  /** Добавить исполнителя на маршрут */
  public onAddToTreeExecutor(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    const routeId = this.route.route.id;
    const phaseTypeId = this.activePoint.phaseTypeId;
    const phaseId = this.activePointWithAdditionalInfo?.subPhaseId;

    this.agreementNavigate.onAddToTreeExecutor(
      routeId,
      phaseId,
      phaseTypeId,
      this.activePoint,
      this.documentPackage.id,
    );
  }

  /** Добавить согласующего на маршрут */
  public onAddToTreeCoordinators(): void {
    const routeId = this.route.route.id;
    const phaseTypeId = this.activePoint.phaseTypeId;
    const phaseId = this.activePointWithAdditionalInfo?.subPhaseId;

    this.agreementNavigate.onAddToTreeCoordinators(
      routeId,
      phaseId,
      phaseTypeId,
      this.activePoint,
      this.documentPackage.id,
    );
  }

  /** Нажатие кнопки "Взять в работу" */
  public async onStartDevelopment(): Promise<void> {
    const category = this.store.selectSnapshot(ActiveCategoryState.getActiveCategory);

    const modal = await this.modalController.create({
      backdropDismiss: false,
      component: StartDevelopmentComponent,
      componentProps: {
        data: {
          routeId: this.route.route.id,
          category,
          pointRole: this.activePoint.pointRoleId,
          phaseTypeId: this.activePoint.phaseTypeId,
          pointId: this.activePoint.id,
        },
      },
      cssClass: 'start-development',
    });

    modal.present();

    const res = await modal.onDidDismiss();
    if (res.data) {
      this.successStartedDevelopment.emit();
    }
  }

  /** Нажатие кнопку "Отправить на согласование" */
  public onStartApprovement(): void {
    this.agreementNavigate.onStartApprovement(this.route.route.id, this.activePoint.id, this.documentPackage.id);
  }

  /** Нажатие кнопку "Отправить на согласование с замечаниями" */
  public onStartApprovementWithRemarks(): void {
    this.agreementNavigate.onStartApprovementWithRemarks(
      this.route.route.id,
      this.activePoint.id,
      this.documentPackage.id,
    );
  }

  /** Нажатие кнопку "Ознакомлен" */
  public onApproveIntroduction(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    this.agreementNavigate.onApproveIntroduction(this.route.route.id, this.documentPackage.id, this.activePoint.id);
  }

  /** Нажатие кнопку "Передать на ознакомление" */
  public onAddIntroducer(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    const routeId = this.route.route.id;
    this.agreementNavigate.onAddIntroducer(routeId, this.documentPackage.id, this.activePoint.id);
  }

  public async onStartRework(): Promise<void> {
    const needRefresh = await this.refreshRouteHelper.checkActualInfoAboutDocumentPackage().toPromise();
    if (needRefresh) {
      return;
    }

    this.documentApi
      .startRework(this.route.route.id, this.activePoint.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.packagesStore.updateSelectedDocumentPackage();
      });
  }

  public onStartOuterApprovement(): void {
    const haveDocuments = this.haveDocuments();
    if (!haveDocuments) {
      this.showErrorMessageVersionsNotExist();
      return;
    }

    this.agreementNavigate.onStartOuterApprovement(this.route.route.id, this.documentPackage.id, this.activePoint.id);
  }

  private initializeLogic(point: RoutePoint): void {
    if (!point) {
      return;
    }

    const activePointLogic = PointLogicFactory.getLogicImplementation(point, this.route);

    this.isShowApprove = activePointLogic.canApprove();
    this.isShowApproveWithRemarks = activePointLogic.canApproveWithRemarks();
    this.isShowRedirect = activePointLogic.canRedirect();
    this.isShowStartApprovement = activePointLogic.canStartApprovement();
    this.isShowAddCoordinator = activePointLogic.canAddCoordinator();
    this.isShowAddExecutor = activePointLogic.canAddExecutor();
    this.isShowSendToLeader = activePointLogic.canSendToLeader();
    this.isShowSendRemarksToAuthor = activePointLogic.canSendRemarksToAuthor();
    this.isShowStartDevelopment = activePointLogic.canStartDevelopment();
    this.isShowReturn = activePointLogic.canReturn();
    this.isShowAddIntroducer = activePointLogic.canAddIntroducer();
    this.isShowApproveIntroduction = activePointLogic.canApproveIntroduction();
    this.isShowStartRework = activePointLogic.canStartRework();
    this.isStartOuterApprovement = activePointLogic.canStartOuterApprovement();
  }

  private async approveWithoutRedirect(type: ApproveTypeEnum): Promise<void> {
    const needRefresh = await this.refreshRouteHelper.checkActualInfoAboutDocumentPackage().toPromise();
    if (needRefresh) {
      return;
    }

    const modal = await this.showModalConfirmAction(type);

    const res = await modal.onDidDismiss();
    if (!res.data) {
      return;
    }

    const documentsForDecisionAction = filteringBlankDocuments(this.documentPackage.documents);
    const canSigningWithSign = this.agreementSignService.hasSignerCategoryOrNeededRoles(this.activePoint);
    const defaultCertificate = canSigningWithSign ? this.settings.defaultCertificateNpa : null;
    const paramsForBaseAgreementsGetRequest: ParamsForPreparationSigning = {
      documentPackage: this.documentPackage,
      paramsSigning: {
        certificateId: defaultCertificate?.isCloud ? +defaultCertificate.id : null,
        pointId: this.activePoint.id,
        revisions: documentsForDecisionAction.map((d) => getDocumentRevision(d)),
        text: '',
      },
      author: this.documentPackage.documentPackageAdditionalInfo.activeRouteAuthor,
    };

    this.agreementSignService.signDocumentPackage(type, paramsForBaseAgreementsGetRequest);
  }

  private async showModalConfirmAction(type: ApproveTypeEnum): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      id: modalIdConfirmAction,
      backdropDismiss: false,
      component: ConfirmActionComponent,
      cssClass: 'confirm-action',
      componentProps: {
        dpId: this.documentPackage.documentPackageAdditionalInfo.packageNumber,
        type,
      },
    });

    modal.present();

    return modal;
  }

  private fillWarningMessage(): void {
    this.warningMessageAboutDisablingButton = '';

    if (this.isShowApprove && this.isDisabledApprove) {
      this.warningMessageAboutDisablingButton = remarksTextMessageByActionType(
        ApproveTypeEnum.approve,
        this.route,
        this.activePoint,
        this.packagesStore.hasRemarksAlternatives,
      );
    }
    if (this.isShowApproveWithRemarks && this.isDisabledApproveLuz) {
      this.warningMessageAboutDisablingButton = remarksTextMessageByActionType(
        ApproveTypeEnum.approveLuz,
        this.route,
        this.activePoint,
        this.packagesStore.hasRemarksAlternatives,
      );
    }
  }

  private checkCertificate(): boolean {
    const canSigningWithSign = this.agreementSignService.hasSignerCategoryOrNeededRoles(this.activePoint);
    const defaultCertificate = canSigningWithSign ? this.settings.defaultCertificateNpa : null;

    this.agreementSignService.setSign(defaultCertificate);

    const validSignature = this.agreementSignService.isValidSignatures();

    if (!validSignature) {
      this.agreementSignService.showInvalidCertificateError();
    }

    return validSignature;
  }

  private haveDocuments(): boolean {
    const versions = getAllActualVersions(this.documentPackage.documents);
    const hasAnyDocumentInMainGroup = hasAnyDocumentInMainDocumentGroup(this.documentPackage.documents);

    return hasAnyDocumentInMainGroup && Boolean(versions.length);
  }

  private showErrorMessageVersionsNotExist(): void {
    this.modalService.presentTemplateModal({
      title: GENERAL_TEXT_ERRORS.actionCannotBePerformed,
      text: DOCUMENTS_TEXT_ERRORS.versionsNotExist,
      buttonLabel: 'OK',
      cssClass: 'error-document-version',
      titleWidth: 324,
    });
  }
}
