import { Pipe, PipeTransform } from '@angular/core';
import { OriginOivEnum } from '@npaShared/enums/document-package-origin-oiv.enum';
import { isLocalLegalActDocumentPackage } from '@npaShared/functions/project-type-document-package.functions';
import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';

@Pipe({
  name: 'approvalPeriodHeader',
})
export class ApprovalPeriodHeaderPipe implements PipeTransform {
  public transform(value: DocumentPackageShortResponseModel): string {
    const isLocalLegalAct = isLocalLegalActDocumentPackage(value);
    if (isLocalLegalAct) {
      return 'Предельный срок согласования';
    }
    return value?.typeOriginOiv === OriginOivEnum.internal
      ? 'Плановый срок согласования'
      : 'Регламентный срок согласования';
  }
}
