import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';

import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { DocumentApiService } from '@npaApi/document.api.service';
import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { DocumentsControlAlternatives } from '@npaShared/models/document/documents-control-alternatives.model';
import { Route } from '@npaShared/models/route/route.models';
import { DocumentPackageResponseModel } from '@npaShared/models/document-package/document-package.response.model';
import { DocumentPackageAdditionalInfoResponseModel } from '@npaShared/models/document-package/document-package-additional-info.response.model';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { Store } from '@ngxs/store';
import { DocumentPackagesState } from '@npaShared/store/document-packages/document-packages.state';
import { NavController } from '@ionic/angular';
import { MenuListNpaItemModel } from '@models/main-screen/menu-list-npa-item.model';
import { menuItemsAisSd } from '@const/main-screen-categories-aissd.const';
import { ActiveCategoryState } from '@npaShared/store/active-category/active-category.state';
import { FiltersService } from '@npaDP/services/filters.service';
import { ProjectGroupTypeState } from '@npaShared/store/project-group-type/project-group-type.state';
import { OibRolesEnum } from '@npaShared/enums/oib-roles.enum';
import { ProjectGroupTypeEnum } from '@npaShared/enums/project-group-type.enum';
import { DashboardSearchResponseModel } from '@npaShared/models/dashboard-search.response.model';
import { DashboardApiService } from '@npaApi/dashboard.api.service';
import { SetTotalCountDocumentPackages } from '@npaShared/store/document-packages/document-packages.action';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';
import { routeNames } from 'src/app/app-routing/route-names.const';
import { getSigningRevision } from '@npaShared/helpers/document-revision';
import { DocumentsState } from '@store/documents/documents.state';
import { CurrentUserAissdStoreService } from './current-user-aissd-store.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentPackagesStoreService {
  /** Выбранный ПД */
  private selectedDocumentPackage$ = new BehaviorSubject<DocumentPackageStoreModel>(null);

  private isInProgress$ = new BehaviorSubject<boolean>(false);

  private isError$ = new BehaviorSubject<boolean>(false);

  private isSearch$ = new BehaviorSubject<boolean>(false);

  /** Признак наличия альтернативы в хотя бы одной ревизии */
  private isHasRemarksAlternatives$ = new BehaviorSubject<boolean>(false);

  private documentsAlternatives$ = new BehaviorSubject<DocumentsControlAlternatives[]>([]);

  /** скрыть ли левую часть со списком ПД */
  private collapseLeftSide$ = new BehaviorSubject(false);

  private projectGroupTypeId: number;
  private searchText: string;

  private currentPageNumber$ = new BehaviorSubject<number>(0);
  private pagesCount$ = new BehaviorSubject<number | undefined>(undefined);

  constructor(
    private documentPackageApi: DocumentPackageApiService,
    private documentApi: DocumentApiService,
    private store: Store,
    private navController: NavController,
    private filtersService: FiltersService,
    private currentUserStore: CurrentUserAissdStoreService,
    private dashboardApi: DashboardApiService,
  ) {}

  public set hasRemarksAlternatives(haveRemarks: boolean) {
    this.isHasRemarksAlternatives$.next(haveRemarks);
  }

  public get hasRemarksAlternatives(): boolean {
    return this.isHasRemarksAlternatives$.value;
  }

  public getSelectedDocumentPackage(): Observable<DocumentPackageStoreModel> {
    return this.selectedDocumentPackage$;
  }

  public getSelectedDocumentPackageSnapshot(): DocumentPackageStoreModel {
    return this.selectedDocumentPackage$.value;
  }

  public getIsInProgress(): Observable<boolean> {
    return this.isInProgress$.asObservable();
  }

  public getIsError(): Observable<boolean> {
    return this.isError$.asObservable();
  }

  public setSelectedDocumentPackage(dpId: number, navigateTo: string[] = []): void {
    this.isInProgress$.next(true);
    this.selectedDocumentPackage$.next(null);
    this.isError$.next(false);

    this.getSelectedDpData$(dpId)
      .pipe(finalize(() => this.isInProgress$.next(false)))
      .subscribe(
        ([documentPackage, documentPackageAdditionalInfo, documents, route]) => {
          const current = this.store.selectSnapshot(DocumentPackagesState.getList).find((p) => p.id === dpId);
          this.selectedDocumentPackage$.next(
            this.mapperToDocumentPackageStoreModel(
              dpId,
              documentPackage,
              documentPackageAdditionalInfo,
              documents,
              route,
              current,
            ),
          );
          if (navigateTo?.length) {
            this.navController.navigateForward(navigateTo);
          }
        },
        () => {
          this.isError$.next(true);
          this.selectedDocumentPackage$.next(
            this.mapperToDocumentPackageStoreModel(dpId, null, null, null, null, null),
          );
        },
      );
  }

  public getSelectedDpData$(
    dpId: number,
  ): Observable<
    [DocumentPackageResponseModel, DocumentPackageAdditionalInfoResponseModel, DocumentInfoResponseModel[], Route]
  > {
    return forkJoin([
      this.documentPackageApi.getDocumentPackage(dpId),
      this.documentPackageApi.getDocumentPackageAdditionalInfo(dpId),
      this.documentApi.searchByDocumentPackageId(dpId),
      this.documentPackageApi.getDocumentRoute(dpId),
    ]);
  }

  public getDpPackageStoreModelFromBackend(
    dpId: number,
    shortResponse: DocumentPackageShortResponseModel = null,
  ): Observable<DocumentPackageStoreModel> {
    return this.getSelectedDpData$(dpId).pipe(
      map(([documentPackage, documentPackageAdditionalInfo, documents, route]) =>
        this.mapperToDocumentPackageStoreModel(
          dpId,
          documentPackage,
          documentPackageAdditionalInfo,
          documents,
          route,
          shortResponse,
        ),
      ),
    );
  }

  public mapperToDocumentPackageStoreModel(
    dpId: number,
    documentPackage: DocumentPackageResponseModel,
    documentPackageAdditionalInfo: DocumentPackageAdditionalInfoResponseModel,
    documents: DocumentInfoResponseModel[],
    route: Route,
    shortResponseModel: DocumentPackageShortResponseModel,
  ): DocumentPackageStoreModel {
    return {
      id: dpId,
      documentPackage,
      documentPackageAdditionalInfo,
      documents,
      shortResponseModel,
      route,
    };
  }

  public updateSelectedDocumentPackage(): void {
    const dp = this.selectedDocumentPackage$.value;
    if (!dp || !dp.id) {
      return;
    }

    this.setSelectedDocumentPackage(dp.id);
  }

  public resetSelectedDocumentPackage(): void {
    this.selectedDocumentPackage$.next(null);
  }

  public setDocumentsAlternatives(documents: DocumentsControlAlternatives[]): void {
    this.documentsAlternatives$.next(documents);
  }

  public getDocumentsAlternatives(): Observable<DocumentsControlAlternatives[]> {
    return this.documentsAlternatives$;
  }

  public getDocumentsAlternativesSnapshot(): DocumentsControlAlternatives[] {
    return this.documentsAlternatives$.value;
  }

  public resetDocumentsAlternatives(): void {
    this.setDocumentsAlternatives([]);
  }

  public getSigningRevision(activeDocument: DocumentInfoResponseModel): string {
    const signingVersionDocuments = this.store.selectSnapshot(DocumentsState.signingVersions);
    const signingVersion = signingVersionDocuments.find((d) => d.documentId === activeDocument.id);
    const signingRevision = getSigningRevision(signingVersion);
    return signingRevision;
  }

  public setIsSearch(value: boolean): void {
    this.isSearch$.next(value);
  }

  public getIsSearch(): Observable<boolean> {
    return this.isSearch$;
  }

  /** показать левую часть со списком ПД */
  public showLeftSide(): void {
    this.collapseLeftSide$.next(false);
  }

  /** скрыть левую часть со списком ПД */
  public hideLeftSide(): void {
    this.collapseLeftSide$.next(true);
  }

  public getCollapseLeftSide(): Observable<boolean> {
    return this.collapseLeftSide$;
  }

  public toggleLeftSide(): void {
    this.getCollapseLeftSideSnapshot() ? this.showLeftSide() : this.hideLeftSide();
  }

  public getCollapseLeftSideSnapshot(): boolean {
    return this.collapseLeftSide$.value;
  }

  /** получить текущюю категорию */
  public getCurrentCategory(): MenuListNpaItemModel {
    const activeFilterName = this.store.selectSnapshot(ActiveCategoryState.getActiveCategory);

    let subcategory = null;
    menuItemsAisSd.forEach((c) => {
      if (!subcategory) {
        subcategory = c?.units?.find((i) => i.type === activeFilterName);
      }
    });
    const category = menuItemsAisSd?.find((i) => i.type === activeFilterName);
    return subcategory ? subcategory : category;
  }

  public getApiFilters(currentPageNumber: number): Observable<DashboardSearchResponseModel> {
    const filters = this.filtersService.getFiltersRequest(
      this.searchText,
      this.getCurrentCategory()?.type,
      this.getProjectTypeId(),
    );

    return this.dashboardApi.getFilters(filters, currentPageNumber);
  }

  public getProjectTypeId(): ProjectGroupTypeEnum {
    const currentUser = this.currentUserStore.getCurrentUserSnapshot()?.currentUser;

    if (this.currentUserStore.hasUserLpaAndNpaRights()) {
      return this.projectGroupTypeId;
    }
    if (currentUser.roles.includes(OibRolesEnum.NPA_GOVERNMENT_USER)) {
      return ProjectGroupTypeEnum.mobileLegalActs;
    }
    if (currentUser.roles.includes(OibRolesEnum.NPA_INTERNAL_USER)) {
      return ProjectGroupTypeEnum.mobileLocalLegalActs;
    }
    return null;
  }

  public getProjectGroupType(): number {
    this.projectGroupTypeId = this.store.selectSnapshot(ProjectGroupTypeState.getActiveProjectGroupType);
    return this.projectGroupTypeId;
  }

  public setSearchText(text: string): void {
    this.searchText = text;
  }

  public setPagesCount(res: DashboardSearchResponseModel): void {
    this.currentPageNumber$.next(res.currentPageNumber);
    this.pagesCount$.next(res.pagesCount);
    this.store.dispatch(new SetTotalCountDocumentPackages(res.totalResultsCount));
  }

  public resetPagesCount(): void {
    this.currentPageNumber$.next(0);
    this.pagesCount$.next(undefined);
    this.store.dispatch(new SetTotalCountDocumentPackages(0));
  }

  public getCurrentPageNumber(): Observable<number> {
    return this.currentPageNumber$.asObservable();
  }

  public getPagesCount(): Observable<number> {
    return this.pagesCount$.asObservable();
  }

  public loadPackages(doCheck = false): Observable<DocumentPackageShortResponseModel[]> {
    if (this.pagesCount$.getValue() === this.currentPageNumber$.getValue() + 1 && doCheck) {
      return of([]);
    }

    return this.getApiFilters(this.currentPageNumber$.getValue() + 1).pipe(
      first(),
      tap((res) => this.setPagesCount(res)),
      map((res) => res.content),
      catchError((e) => {
        this.resetPagesCount();
        throw e;
      }),
    );
  }

  public arrowPackageSelect(
    event: ActionsFooterWithArrow,
    documentPackage: DocumentPackageStoreModel,
    navigate = false,
  ): void {
    let newPackage: DocumentPackageShortResponseModel;

    switch (event) {
      case ActionsFooterWithArrow.left:
        newPackage = this.store.selectSnapshot(
          DocumentPackagesState.getPrevPackage(documentPackage.shortResponseModel),
        );
        if (newPackage) {
          this.setSelectedDocumentPackage(
            newPackage.id,
            navigate ? [routeNames.documentPreview, String(newPackage.id)] : [],
          );
        }
        break;
      case ActionsFooterWithArrow.right:
        newPackage = this.store.selectSnapshot(
          DocumentPackagesState.getNextPackage(documentPackage.shortResponseModel),
        );
        if (newPackage) {
          this.setSelectedDocumentPackage(
            newPackage.id,
            navigate ? [routeNames.documentPreview, String(newPackage.id)] : [],
          );
        }
        break;
    }
  }
}
